<template>
  <div>
    <ApplyMain ref="applymain" stepcurrent=1>
      <div slot="content" class="container">
        <div class="m">
          <div class="mt">
            <h4 class="title">
              <span>入驻联系人信息</span>
            </h4>
          </div>
          <div class="mc">
            <el-form ref="form" :inline-message="true" :model="form" :rules="rules" label-width="185px" :inline="true"
              size="mini">
              <el-form-item label="联系人姓名" prop="contactUserName">
                <el-input v-model="form.contactUserName" style="width:400px"></el-input>
              </el-form-item>
              <el-form-item label="联系人手机" prop="contactUserMobile">
                <el-input v-model="form.contactUserMobile" @focus="oncheckmobile" style="width:400px"></el-input>
              </el-form-item>
              <el-form-item label="联系人邮箱" prop="contactUserEmail">
                <el-input v-model="form.contactUserEmail" style="width:400px"></el-input>
              </el-form-item>
              <div class="form-describe-text">
                <span>入驻过程中用于接收牛豆豆审核结果，请务必仔细确认。<br>建议使用QQ、网易邮箱，189、自主企业邮箱可能无法正常收取邮件。</span>
              </div>
              <div class="t-r">
                <el-form-item>
                  <!-- <el-button>取消</el-button> -->
                  <el-button class="btn" type="primary" @click="onsubmitForm">下一步，完善主体信息</el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
        <el-dialog :visible.sync="mobiledialogVisible" center title="手机验证" top="25vh" width="550px"
          :before-close="handleClose">
          <el-row>
            <el-form ref="formmobile" :inline-message="true" :model="mobileform" :rules="rulesmobile"
              label-width="100px" :inline="true" size="mini">
              <div>
                <el-form-item label="联系人手机" prop="contactUserMobile">
                  <el-input v-model="mobileform.contactUserMobile" style="width:240px"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="验证码" prop="contactUserMobileVerifyCode">
                  <el-input style="width:105px" @input="changeInput()" class="input-number" placeholder=""
                    v-model="mobileform.contactUserMobileVerifyCode" />
                  <el-button size="small" @click="getCode('formmobile')" style="margin-left:9px">{{codeMsg}}</el-button>
                  <!-- <span @click="getCode('ruleForm')">{{codeMsg}}</span> -->
                </el-form-item>
              </div>
              <div class="btn-group" style="margin-left:100px;">
                <el-form-item>
                  <el-button @click="handleClose">取消</el-button>
                  <el-button class="btn" @click="onsubmitcheckmobileForm('formmobile')" type="primary">确定</el-button>
                </el-form-item>
              </div>
            </el-form>
          </el-row>
        </el-dialog>
        <el-dialog :visible.sync="emaildialogVisible" center title="邮箱验证" top="25vh" width="550px"
          :before-close="handleClose">
          <el-row>
            <el-form ref="formemail" :inline-message="true" :model="form" :rules="rules" label-width="100px"
              :inline="true" size="mini">
              <div>
                <el-form-item label="邮箱" prop="contactUserMobile">
                  <el-input v-model="form.contactUserMobile" style="width:240px"></el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="邮箱验证码" prop="contactUserMobile">
                  <el-input style="width:105px" class="input-number" placeholder="" v-model="form.contactUserMobile" />
                  <el-button size="small" style="margin-left:9px">获取邮箱验证码</el-button>
                  <!-- <span @click="getCode('ruleForm')">{{codeMsg}}</span> -->
                </el-form-item>
              </div>
              <div class="btn-group" style="margin-left:100px;">
                <el-form-item>
                  <el-button @click="handleClose">取消</el-button>
                  <el-button class="btn" @click="onsubmitForm('elForm')" type="primary">确定</el-button>
                </el-form-item>
              </div>
            </el-form>
          </el-row>
        </el-dialog>
      </div>
    </ApplyMain>
  </div>
</template>
<script>
import {
  sendcompanyintocontactusermobileverifysms,
  companyintocontactmobileverify,
  getcompanyintocontact,
  companyintocontact,
} from '@/api/user';
import ApplyMain from '@/components/applymain.vue';
import Cookies from 'js-cookie';
export default {
  name: 'contact',
  components: {
    ApplyMain,
  },
  data() {
    var validatorPhone = function (rule, value, callback) {
      let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;

      if (value === '') {
        callback(new Error('手机号不能为空'));
      } else if (!phoneReg.test(value)) {
        console.log(1);
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error('邮箱不能为空'));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error('请输入正确的邮箱格式'));
        }
      }, 100);
    };
    return {
      num: '1',
      dbget: false,
      // 只可请求一次
      isget: false,
      second: 60,
      timer: null,
      codeMsg: '获取短信验证码',
      mobiledialogVisible: false,
      emaildialogVisible: false,
      form: {
        contactUserName: '',
        contactUserMobile: '',
        contactUserEmail: '',
      },
      mobileform: {
        contactUserMobile: '',
        contactUserMobileVerifyCode: '',
      },
      rules: {
        contactUserName: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
        ],
        contactUserMobile: [
          { required: true, validator: validatorPhone, trigger: 'change' },
        ],
        contactUserEmail: [
          { required: true, validator: checkEmail, trigger: 'blur' },
        ],
      },
      rulesmobile: {
        contactUserMobile: [
          { required: true, validator: validatorPhone, trigger: 'blur' },
        ],
        contactUserMobileVerifyCode: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    ongetcompanyintocontact() {
      let params = {};
      getcompanyintocontact(params).then((res) => {
        if (res.data.code === 1) {
          this.form.contactUserMobile = this.mobileform.contactUserMobile =
            res.data.result.contactUserMobile;
          this.form.contactUserName = res.data.result.contactUserName;
          this.form.contactUserEmail = res.data.result.contactUserEmail;
          this.form.id = res.data.result.id;
        }
        // if (this.form.contactUserMobile == '') {
        //   this.form.contactUserMobile = this.mobileform.contactUserMobile;
        // }
      });
    },
    onsubmitcheckmobileForm() {
      let params = {
        contactUserMobile: this.mobileform.contactUserMobile,
        contactUserMobileVerifyCode: this.mobileform
          .contactUserMobileVerifyCode,
        id: 0,
      };
      companyintocontactmobileverify(params).then((res) => {
        if (res.data.code === 1) {
          this.form.contactUserMobile = this.mobileform.contactUserMobile;
          //this.form.id=res.data.result.id;
          this.mobiledialogVisible = false;
          params = {};
          getcompanyintocontact(params).then((res) => {
            if (res.data.code === 1) {
              this.form.id = res.data.result.id;
            }
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    onsubmitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = {
            contactUserName: this.form.contactUserName,
            contactUserMobile: this.form.contactUserMobile,
            contactUserEmail: this.form.contactUserEmail,
            id: this.form.id,
          };
          companyintocontact(params).then((res) => {
            if (res.data.code === 1) {
              this.$router.push('/user/apply/information');
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      });
    },
    changeInput() {
      var phone = /^[1]([3-9])[0-9]{9}$/;
      this.dbget = false;
      if (phone.test(this.mobileform.contactUserMobile)) {
        this.dbget = true;
        this.num = 1;
      }
    },
    getCode(formName) {
      this.dbget = true;
      if (this.mobileform.contactUserMobile == '') {
        this.$message.error('请输入手机号');
        return;
      }
      if (this.second != 60) {
        this.$message.error(`请${this.second}秒后重试`);
        return;
      }
      sendcompanyintocontactusermobileverifysms({
        userMobile: this.mobileform.contactUserMobile,
      }).then((res) => {
        if (res.data.code === 1) {
          this.$message({
            message: '发送成功',
            type: 'success',
          });
          this.codeMsg = `${this.second}s后重试`;
          this.timer = setInterval(() => {
            this.second--;
            this.codeMsg = `${this.second}s后重试`;
            if (this.second == 0) {
              clearInterval(this.timer);
              this.second = 60;
              this.codeMsg = '获取验证码';
            }
          }, 1000);
        } else {
          this.$message.error(res.data.message);
          //this.phone = false;
        }
      });
      return;
    },
    oncheckmobile() {
      this.mobiledialogVisible = true;
    },
    oncheckemail() {
      this.emaildialogVisible = true;
    },
    handleClose() {
      this.mobiledialogVisible = false;
      this.emaildialogVisible = false;
    },
  },
  created() {
    this.ongetcompanyintocontact();
  },
};
</script>
<style  scoped>
h4.title {
  font-size: 16px;
  font-family: 'Microsoft Yahei';
  line-height: 180%;
  color: #333333;
  margin-bottom: 5px;
  float: left;
  margin-right: 10px;
}
.container >>> .el-form-item {
  margin-bottom: 8px;
}
.container >>> .el-form-item__label {
  font-size: 12px;
}
.container >>> .el-input__inner {
  height: 30px;
  font-size: 12px;
  padding-left: 10px;
  line-height: 32px;
}
.container .form-describe-text {
  margin-left: 185px;
  color: #999;
  top: -12px;
  line-height: 20px;
  margin-top: 5px;
  position: relative;
  font-size: 12px;
}
.container >>> .el-button {
  padding: 8px 20px;
}
.container >>> .btn {
  border: 1px solid #c6e2ff;
  background-color: #31b7f4;
}
.btn:hover,
.btn-normal:hover {
  border: 1px solid;
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
</style>